import Select from "components/innerComponents/Select";
import useGenericActions from "hook/useGenericAction";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DONATION_HISTORY } from "../../../navigation/routes";
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useMutation, useQuery } from "react-query";
import { notify } from "components/Alerts";
import { useRecoilValue } from "recoil";
import { userSelector } from "hook/state/user";
import LoadingIndicator from "components/LoadingIndicator";
import { formatNumber, scrollToErrors } from "utils/functions";
import SelectBox from "components/common/Selects/SelectBox";
import { donation_groups } from 'utils/constants'
import useDonation from "hook/useDonation";

const validationSchemaMakeDonations = Yup.object({
    amount: Yup.string()
        .matches(
            /^\d+$/,
            'field accepts only numbers'
        )
        .required('Must not be empty'),
    donation_group: Yup.object()
        .required('Field cannot be empty'),
    donation_cause: Yup.object()
        .required('Field cannot be empty'),
   
})
const donation_types = [
    { id: 1, value: 'one-off', label: 'One Off' },
    { id: 2, value: 'recurrent', label: 'Recurrent' }]
const donation_cycles = [
    { id: 1, value: 'monthly', label: 'Monthly' },
    { id: 2, value: 'quarterly', label: 'Quarterly' },
    { id: 3, value: 'yearly', label: 'Yearly' }]

const Donation = () => {
    const user = useRecoilValue(userSelector);
    const param = useLocation();
    const [selectedCycle, setSelectedCycle] = useState('')
    const [selectedStatus, setSelectedStatus] = useState(null)
    const { makeDonation, getDonationTypes, getDonationStatus, getFundRaisings, payFundRaisings } = useGenericActions()
    
    const {donationCause,  categoryId,
        setCategoryId,}= useDonation();
    const { data: fundraiserCause } = useQuery(['fundraising', 'generic'], getFundRaisings, {
        select: (res) => {
            const permOpt = res.data.fund_raising.data.map(item => {
                return { id: item.id, value: item.id, label: item.name }
            })

            return permOpt
        },
        onError: err => {
            console.log(err)
            // notify('post creation failed!', { type: 'error' })
        },
        staleTime: Infinity,
        cacheTime: Infinity
    })

    const { data: donationTypes } = useQuery(['donations'], getDonationTypes, {
        select: (res) => {
            const permOpt = res.data.donations.data.map(item => {
                return { id: item.id, value: item.id, label: item.tagline }
            })

            return permOpt
        },
        onError: err => {
            console.log(err)
            // notify('post creation failed!', { type: 'error' })
        },
        staleTime: Infinity,
        cacheTime: Infinity
    })
    const { data: donationStatus } = useQuery(['donations status'], getDonationStatus, {
        select: (res) => {
            const permOpt = res.data.donor_statuses.data.map(item => {
                return { id: item.id, value: item.id, label: item.name }
            })

            return permOpt
        },
        onError: err => {
            console.log(err)
            // notify('post creation failed!', { type: 'error' })
        },
        staleTime: Infinity,
        cacheTime: Infinity
    })
    const makeDonationMutation = useMutation(makeDonation, {
        onSuccess: (res) => {
            window.open(res.data.authorization_url, '_self');
        },
        onError: err => {
            notify(err.message, { type: 'error' })
        }
    })



    const fundraiserDonationMutation = useMutation(payFundRaisings, {
        onSuccess: (res) => {
            window.open(res.data.authorization_url, '_self');
        },
        onError: err => {
            notify(err, { type: 'error' })
        }
    })


    const formik = useFormik({
        validationSchema: validationSchemaMakeDonations,
        enableReinitialize: true,
        initialValues: {
            amount: '',
            donation_group: '',
            donation_cause: '',
            donation_type: '',
            donation_cycle: '',
        },
        onSubmit: (values) => {
            // debugger
            const details = {
                donor_phone_number: user?.phone_home || user?.phone_mobile ,
                donation_id: values.donation_cause.id,
                amount: values.amount,
                donor_status_id: 1,
                donor_name: `${user?.first_name} ${user?.last_name}`,
                donor_email: user?.email,
                donation_type: selectedStatus.value,
                donation_category: values.donation_group.value,
                donation_cycle: values.donation_cycle || 'one-off',
                callbackUrl: `${window.location.origin}/users/donation-history`
            }
            if (values.donation_group.value === 'fundraisers') {
                const fundData = {
                    callbackUrl: `${window.location.origin}/users/donation-history`,
                    fund_raising_id: values.donation_cause.id,
                    email: values.email,
                    first_name: user?.first_name,
                    last_name: user?.last_name,
                    amount: values.amount,
                    donation_type: selectedStatus.value,
                    currency: 'NGN',
                    donation_cycle: values.donation_cycle,
                }
                fundraiserDonationMutation.mutate(fundData)
            } else {
                makeDonationMutation.mutate(details)
            }
        },
    })

    useEffect(() => {
        if (param?.state?.amount) {
            notify('complete the form to make a donation', {
              type: 'success',
              duration: 3400,
            });
            formik.setFieldValue('amount',param.state?.amount);
          }
    }, []);

    const handleSelectchange = (e, name) => {
        formik.setFieldValue(name, e)
        if(e?.value!="fundraisers")
        {
            setCategoryId(e.id);
        }
        
    }

    return (
        <>
            <div className="container-fluid px-4">
                <div className="d-sm-flex align-items-start justify-content-between mt-4">
                    <div>
                        <h1>Donation</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><span role={'button'} >Donation</span></li>
                            <li className="breadcrumb-item active">Donate</li>
                        </ol>
                    </div>
                    <div
                        className="d-flex flex-wrap align-items-center justify-content-sm-end gap-2 mb-2">
                        <a className="btn btn-primary me-2" href={DONATION_HISTORY}>View Donation History</a>
                    </div>
                </div>
                <div className="card mb-4">
                    <div className="card-body">With your help, we take on the big issues wherever it matters. We confront the problems, the causes that keep people
                        poor, like inequality, discrimination against women, climate change, and the eviction of people from their land. We
                        support and assist people to build better lives for themselves. And we won’t stop until every person on the planet can
                        enjoy a life free from poverty.
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <div className="card mb-4">
                            <div className="card-body">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="mb-3">
                                        <SelectBox
                                            selectName="donation_group"
                                            selectLabel={`Donation category`}
                                            itemList={donation_groups}
                                            isMulti={false}
                                            value={formik.values.donation_group}
                                            onChange={handleSelectchange}
                                            selectOption={`Category`}
                                            error={formik.errors.donation_group && formik.touched.donation_group ? formik.errors.donation_group : null}
                                        />
                                    </div>
                                    {formik.values?.donation_group &&
                                        <div className="mb-3">
                                            <SelectBox
                                                selectName="donation_cause"
                                                selectLabel={`Donation Cause`}
                                                // itemList={formik.values?.donation_group?.value === 'fundraisers' ? fundraiserCause : formik.values?.donation_group?.value === 'others' ? donationTypes : []}
                                                itemList={
                                                    formik.values?.donation_group?.value === 'fundraisers'
                                                      ? fundraiserCause
                                                      : formik.values?.donation_group?.value === 'activity'|| 
                                                      formik.values?.donation_group?.value === 'challenge'|| 
                                                      formik.values?.donation_group?.value === 'campaign'
                                                        ? donationCause
                                                        : []
                                                  }
                                                isMulti={false}
                                                value={formik.values.donation_cause}
                                                onChange={handleSelectchange}
                                                selectOption={`Select cause`}
                                                error={formik.errors.donation_cause && formik.touched.donation_cause ? formik.errors.donation_cause : null}
                                            />
                                        </div>}

                                    <div className="mb-3">
                                        <div className="form-floating">
                                            <Select id="status" name={'donor_status_id'} className="form-select" 
                                            selected={selectedStatus} 
                                            handleChange={(e) => { setSelectedStatus(JSON.parse(e.target.value)) }} options={donation_types} />
                                            <label htmlFor="status">Donation type</label>
                                        </div>
                                        {formik.errors.donor_status_id && formik.touched.donor_status_id && <small className="error">{formik.errors.donor_status_id} </small>}
                                    </div>

                                    {selectedStatus?.value === 'recurrent' && <div className="form-floating mb-3">
                                        <Select id="donation_cycle" className="form-select" selected={selectedCycle} handleChange={(e) => {
                                            formik.setFieldValue('donation_cycle', JSON.parse(e.target.value)?.value); setSelectedCycle(JSON.parse(e.target.value))
                                        }} options={donation_cycles} />
                                        <label htmlFor="donation_cycle">Donation Cycles</label>
                                    </div>}

                                    {/* {!formik.values.donor_phone_number &&
                                        <div className=" mb-3">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" name="donor_phone_number" aria-label="donor_phone_number" value={formik.values.donor_phone_number} onChange={formik.handleChange} onBlur={formik.handleBlur} aria-describedby="basic-addon1" />
                                                <label htmlFor="donationType">Phone number</label>
                                            </div>
                                        </div>
                                    } */}

                                    <div className="mb-3">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">
                                                   {param?.state?.currency==="USD"?'$':'₦'} 
                                                    </span>
                                            </div>
                                            <input type="text" className="form-control" name="amount"
                                             placeholder="5000" aria-label="amount" 
                                             value={formik.values.amount} 
                                             onChange={formik.handleChange} onBlur={formik.handleBlur} aria-describedby="basic-addon1" />
                                        </div>
                                        {formik.errors.amount && formik.touched.amount && <small className="error">{formik.errors.amount} </small>}
                                    </div>
                                    <div className="flex justify-content-end">
                                        <button type="submit" className="btn btn-primary" disabled={makeDonationMutation.isLoading} onClick={() => scrollToErrors(formik.errors)}>{makeDonationMutation.isLoading && <LoadingIndicator />}Donate</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Donation;
