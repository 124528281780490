export const token = 'userToken'
export const excludedFields = [
  'admin_creator_id',
  'email_verified_at',
  'updated_at',
  'deleted_at',
  'profile_picture',
  'creator',
  'media',
  'id',
  'is_featured',
  'featured_at',
  'is_published',
  'blocked_at',
  'is_approved',
  'approved_at',
  'is_active',
  'is_archived',
  'postable_type',
  'postable_id',
  'slug',
  'post_type',
  'email_verified_at',
  'city_id',
  'state_id',
  'status_id',
  'category_id',
  'pivot',
  'guard_name',
  'date_joined',
  'donation_creator',
  'age',
  'changed_password',
  'country_id'
];
export const excludedMessageFields = [
  'admin_creator_id',
  // 'unique_id',
  // 'address',
  // 'phone',
  'first_name',
  'last_name',
  'email_verified_at',
  'updated_at',
  'deleted_at',
  'profile_picture',
  'creator',
  'media',
  'id',
  'is_featured',
  'featured_at',
  'is_published',
  'blocked_at',
  'is_approved',
  'approved_at',
  'is_active',
  'is_archived',
  'postable_type',
  'postable_id',
  'slug',
  'post_type',
  'email_verified_at',
  'city_id',
  'state_id',
  'status_id',
  'category_id',
  'pivot',
  'guard_name',
  'date_joined',
  'donation_creator',
  'age',
  'changed_password',
  'country_id'
];
export const donation_groups = [
  // { id: 1, value: 'Old fundraisers', label: 'Old Fundraisers' },
  // { id: 5, value: 'fundraiser', label: 'Fundraiser' },
  { id: 2, value: 'activity', label: 'Activity' },
  { id: 3, value: 'challenge', label: 'Challenge' },
  // { id: 4, value: 'campaign', label: 'Campaign' }
]
