const Footer = () => {
    return (
        <footer className="bg-dark">
            <div className="container px-4 px-lg-5">
                <div className="row">
                    <div className="col-lg-3 col-sm-12">
                        <div className="widget text-widget">
                            <div className="title text-white">
                                <h4>ActionAid Nigeria</h4>
                            </div>
                            <div className="widget-content text-white">
                                <p>ActionAid Nigeria is a member of the ActionAid global federation. We maintain a strong relationship of interdependence and mutual accountability within the international federation, ensuring a strong balance between self-rule and shared-rule.</p>
                            </div>
                            <div className="mt-4">
                                <a className="text-link" href="#who-we-are">Learn more</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                        <div className="widget social-icons">
                            <div className="title text-white">
                                <h4>Join The Conversation</h4>
                            </div>
                            <ul className="menu-list">
                                <li className="item">
                                    <p><a href="https://facebook.com/ActionAidNigeria" target="_blank" rel="noreferrer"><i className="bi bi-facebook"></i>  ActionAid Nigeria</a></p>
                                </li>
                                {/* <li className="item">
                                    <p><a href="https://www.linkedin.com/in/communitysponsorship/" target="_blank" rel="noreferrer"><i className="bi bi-linkedin"></i>  Community Sponsorship</a></p>
                                </li> */}
                                <li className="item">
                                    <p><a href="https://x.com/ActionAidNG" target="_blank" rel="noreferrer"><i className="bi bi-twitter"></i>  ActionAid Nigeria</a></p>
                                </li>
                                <li className="item">
                                    <p><a href="https://instagram.com/ActionAidNigeria" target="_blank" rel="noreferrer"><i className="bi bi-instagram"></i>  ActionAid Nigeria</a></p>
                                </li>
                                <li className="item">
                                    <p><a href="https://www.linkedin.com/company/action-aid-nigeria/" target="_blank" rel="noreferrer"><i className="bi bi-linkedin"></i>  ActionAid Nigeria</a></p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                        <div className="widget menu">
                            <div className="title text-white">
                                <h4>Get Involved</h4>
                            </div>
                            {/* https://actionaid-ngr.org/sponsor-a-project/
                            https://actionaid-ngr.org/donate/
                            http://actionaid-ngr.org/become-a-supporter/
                            https://actionaid-ngr.org/payroll-giving/
                            https://actionaid-ngr.org/corporate-social-responsibilities/ */}
                            <ul className="menu-list">
                                <li className="item"><p><a href="#" target="_blank" rel="noreferrer">Sponsor a Project</a></p></li>
                                <li className="item"><p><a href="#" target="_blank" rel="noreferrer">Fundraise</a></p></li>
                                <li className="item"><p><a href="#" target="_blank" rel="noreferrer">Become a Supporter</a></p></li>
                                <li className="item"><p><a href="#" target="_blank" rel="noreferrer">Payroll Giving</a></p></li>
                                <li className="item"><p><a href="#" target="_blank" rel="noreferrer">CSR</a></p></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                        <div className="widget text-widget">
                            <div className="title text-white">
                                <h4>Community Sponsorship</h4>
                            </div>
                            <div className="widget-content text-white">
                                {/* <p>Community Sponsorship© is a voluntary individual giving platform where you make regular (monthly/annual) donations in a structured way in support of ActionAid’s work of lifting the most impoverished and excluded communities across the country out of poverty.</p> */}
                                <p>The ActionAid Community Sponsorship Initiative is a voluntary giving program that enables individuals and organizations to support community development in Nigeria. By providing regular funding, sponsors can empower vulnerable communities, including out-of-school children, women, poor farmers, and those affected by emergencies and conflicts, to access essential services and infrastructure.</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="copyright small text-center">Copyright &copy; {new Date().getFullYear()} - <span>ActionAid Nigeria Community Sponsorship Initiative</span></div>
            </div>
        </footer>
    );
}

export default Footer;
