import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthLayout from "../../../components/innerComponents/AuthLayout";
import { useFormik } from 'formik'
import * as Yup from 'yup'
import useAuth from "hook/useAuth";
import { useMutation } from 'react-query'
import { useState } from "react";
import LoadingIndicator from "components/LoadingIndicator";
import { notify } from "components/Alerts";
import useSignOnFee from "hook/useSignOnFee";
import { DASHBOARD, FORUM, USER_ROUTES } from "navigation/routes";
import { scrollToErrors } from "utils/functions";

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Must be valid email')
        .required('Email cannot be empty'),
    password: Yup.string()
        .required('Password cannot be empty'),
})
const SigninPage = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { loginAdmin, loginUser } = useAuth()
    const [loginAs] = useState(location.pathname.includes('admin') ? 'admin' : 'user')

    const { mutate, isLoading } = useMutation(loginAs === 'admin' ? loginAdmin : loginUser, {
        onSuccess: (data) => {
            notify(data?.message || 'login successfull', { type: 'success' })
            if (loginAs === 'user') {
                navigate(`${USER_ROUTES}`, { replace: true });
            }
        },
        onError: (error) => {
            notify(error, { type: 'error' })
        }
    })

    const formik = useFormik({
        validationSchema,
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: (values, resetForm) => {
            mutate(values)
        },
    })
    return (
        <AuthLayout>
            <div className="card shadow-lg border-0 rounded-lg mt-5 mb-5">
                <div className="card-header bg-red text-white"><h3
                    className="text-center font-weight-light my-4">Login {loginAs === 'admin' && ` as ${loginAs}`}</h3>
                </div>
                <div className="card-body">
                    <form onSubmit={formik.handleSubmit}>
                        {/* {loginAs !== 'admin' && <> <div className="text-center flex flex-column gap-2 social-btn">
                            <a href="#" className="btn btn-info2 btn-block"><i className="fa-brands fa-facebook"></i> Sign in with <b>Facebook</b></a>
                            <a href="#" className="btn btn-info btn-block"><i className="fa-brands fa-twitter"></i> Sign in with <b>Twitter</b></a>
                            <a href="#" className="btn btn-danger btn-block"><i className="fa-brands fa-google"></i> Sign in with <b>Google</b></a>
                        </div><div className="or-seperator"><i>or</i></div></>} */}

                        <div className="form-floating mb-3">
                            <input className="form-control" id="inputEmail" name="email" type="email"
                                placeholder="name@example.com" value={formik.values.email} onChange={formik.handleChange} />
                            <label htmlFor="inputEmail">Email address</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input className="form-control" id="inputPassword"
                                type="password" placeholder="Password" name="password" value={formik.values.password} onChange={formik.handleChange} />
                            <label htmlFor="inputPassword">Password</label>
                        </div>
                        <div className="form-check mb-3">
                            <input className="form-check-input" id="inputRememberPassword"
                                type="checkbox" value="" />
                            <label className="form-check-label"
                                htmlFor="inputRememberPassword">Remember Password</label>
                        </div>
                        <div
                            className="d-flex align-items-center justify-content-between mt-4 mb-0">
                            <Link className="small" to={loginAs === 'admin' ? '/admin/forgotpassword' : "/forgotpassword"}>Forgot Password?</Link>
                            <button className="btn btn-primary" type="submit" disabled={isLoading} onClick={() => scrollToErrors(formik.errors)}>{isLoading && <LoadingIndicator />} Login</button>
                        </div>
                    </form>
                </div>

                {loginAs === 'user' && <div className="card-footer">
                    <div className="flex justify-content-sm-between">
                        <span>Don't have an account?
                            <Link className="small" to="/signup">Signup</Link>
                        </span>

                    </div>

                </div>}
            </div>
        </AuthLayout>
    );
}

export default SigninPage;
