import { notify } from 'components/Alerts';
import Input from 'components/innerComponents/Input';
import LoadingIndicator from 'components/LoadingIndicator';
import { useFormik } from 'formik';
import useAuth from 'hook/useAuth';
import useDonation from 'hook/useDonation';
import { useEffect, useState } from 'react';
import { Suspense } from 'react';
import { useMutation } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'utils/classNames';
import { scrollToErrors } from 'utils/functions';
import { months } from '../../pages/innerPages/Signup';
import CitySelect from './Selects/CitySelect';
import CountrySelect from './Selects/CountrySelect';
import StateSelect from './Selects/StateSelect';
import * as Yup from 'yup';
import { DONATE } from 'navigation/routes';
import SelectBox from './Selects/SelectBox';
import ButtonWithTips from 'components/ButtonWithTips';
import PhoneInput from "react-phone-input-2";
const validationSchema = Yup.object({
  email: Yup.string()
    .email('Must be valid email')
    .required('Email cannot be empty'),
  password: Yup.string().required('Password cannot be empty'),
});
const DonationCommon = () => {
  const param = useLocation();
  const navigate = useNavigate();
  const [phoneMobile, setPhoneMobile] = useState(null)
  const {
    handleSelectRegularchange,
    formikRegular,
    formik,
    selectedCity,
    donation_types,
    setSelectedCity,
    selectedState,
    setSelectedState,
    selectedCountry,
    setSelectedCountry,
    categoryId,
    setCategoryId,
    formik2,
    createDonationMutation,
    donation_groups,
    donationCause,
    registerUserMutation,
    donation_cycles,
    handleSelectchange,
    fundraiserCause,
    handleSelect2change,
  } = useDonation();

  useEffect(() => {
    if (param?.state?.amount) {
      document.getElementById('collapseOne').focus();
      notify('complete the form to make a donation', {
        type: 'success',
        duration: 3400,
      });
      formikRegular.setFieldValue('amount',param.state?.amount);
    }
  }, []);

  const { loginUser } = useAuth();

  const { mutate, isLoading } = useMutation(loginUser, {
    onSuccess: (data) => {
      notify(data.message, { type: 'success' });
      navigate(`/users/${DONATE}`);
    },
    onError: (error) => {
      notify(error, { type: 'error' });
    },
  });

 const handlePhoneMobile=(e)=>{
    formikRegular.setFieldValue('phone_mobile',e);
  }

  const handlePhoneHome=(e)=>{
    formikRegular.setFieldValue('phone_home',e);
  }

  const signinValidation = useFormik({
    validationSchema,
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values, resetForm) => {
      mutate(values);
    },
  });

  return (
    <div className="accordion" id="accordionExample">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className={classNames(
              param.state
                ? 'accordion-button bg-primary text-white'
                : 'accordion-button bg-primary text-white collapsed'
            )}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
          >
            Click here for Regular Donation
          </button>
        </h2>
        <div
          id="collapseOne"
          className={classNames(
            param.state
              ? 'accordion-collapse collapse show'
              : 'accordion-collapse collapse'
          )}
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div>
              <p>Choose the tab that best corresponds to your status with us.</p>
              P.S: If you have an account with us, then you are a returning user. 
Kindly select the Returning Donor tab, Login and proceed to Donate

              
              
            </div>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <ButtonWithTips
                  id="profile-tab"
                  tips="You are a first time donor if you have never made a donation on our platform before and do not have an account with us"
                  btnProps={{
                    className: 'nav-link active',
                    id: 'profile-tab',
                    'data-bs-toggle': 'tab',
                    'data-bs-target': '#profile',
                    type: 'button',
                    role: 'tab',
                    'aria-controls': 'profile',
                    'aria-selected': 'false',
                  }}
                >
                  First Time Donors
                </ButtonWithTips>
              </li>
              <li className="nav-item" role="presentation">
                <ButtonWithTips
                  id="home-tab"
                  tips="You are a returning donor if you have ever made a donation on our platform before or have an account with us"
                  btnProps={{
                    className: 'nav-link',
                    id: 'home-tab',
                    'data-bs-toggle': 'tab',
                    'data-bs-target': '#home',
                    type: 'button',
                    role: 'tab',
                    'aria-controls': 'home',
                    'aria-selected': 'true',
                  }}
                >
                  Returning Donors
                </ButtonWithTips>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade"
                id="home"
                role="tabpanel"
                aria-labelledby="returning-tab"
              >
                <form onSubmit={signinValidation.handleSubmit} className="row">
                  <div className="col-6 my-3">
                    <input
                      className="form-control"
                      id="inputEmail"
                      name="email"
                      type="email"
                      placeholder="name@example.com"
                      value={signinValidation.values.email}
                      onChange={signinValidation.handleChange}
                    />
                  </div>
                  <div className="col-6 my-3">
                    <input
                      className="form-control"
                      id="inputPassword"
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={signinValidation.values.password}
                      onChange={signinValidation.handleChange}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-3 mb-0">
                    <Link className="small" to={'/forgotpassword'}>
                      Forgot Password?
                    </Link>
                    <button
                      className="btn btn-primary"
                      type="submit"
                      disabled={isLoading}
                      onClick={() => scrollToErrors(signinValidation.errors)}
                    >
                      {isLoading && <LoadingIndicator />} Login
                    </button>
                  </div>
                </form>
              </div>
              {/* Start ing  */}
          <div
            className="tab-pane fade show active"
            id="profile"
            role="tabpanel"
            aria-labelledby="new-tab"
          >
          <div className="accordion-body">
            <form onSubmit={formikRegular.handleSubmit} className="row">
              <div className="col-sm-6">
                <Input
                  name="first_name"
                  value={formikRegular.values.first_name}
                  type="text"
                  placeholder="First Name"
                  handleChange={formikRegular.handleChange}
                  handleBlur={formikRegular.handleBlur}
                  error={
                    formikRegular.errors.first_name && formikRegular.touched.first_name
                      ? formikRegular.errors.first_name
                      : null
                  }
                />
              </div>
              <div className="col-sm-6">
                <Input
                  name="last_name"
                  value={formikRegular.values.last_name}
                  type="text"
                  placeholder="Last Name"
                  handleChange={formikRegular.handleChange}
                  handleBlur={formikRegular.handleBlur}
                  error={
                    formikRegular.errors.last_name && formikRegular.touched.last_name
                      ? formikRegular.errors.last_name
                      : null
                  }
                />
              </div>
              <div className="col-sm-6">
              <span style={{ fontSize: 'xx-small' }}>&nbsp;</span>
                <Input
                  name="email"
                  value={formikRegular.values.email}
                  type="email"
                  placeholder="Email"
                  handleChange={formikRegular.handleChange}
                  handleBlur={formikRegular.handleBlur}
                  error={
                    formikRegular.errors.email && formikRegular.touched.email
                      ? formikRegular.errors.email
                      : null
                  }
                />
              </div>
              <div className="col-sm-6">
                {/* <Input
                  name="phone_mobile"
                  value={formikRegular.values.phone_mobile}
                  type="tel"
                  placeholder="Phone Number 1(Mobile)"
                  handleChange={formikRegular.handleChange}
                  handleBlur={formikRegular.handleBlur}
                  error={
                    formikRegular.errors.phone_mobile && formikRegular.touched.phone_mobile
                      ? formikRegular.errors.phone_mobile
                      : null
                  }
                /> */}
                <span style={{ fontSize: 'xx-small' }}>Phone Number 1(Mobile)</span>
              <PhoneInput
                country={'ng'}
                preferredCountries={['ng', 'gh', 'ke', 'us', 'uk']}
                excludeCountries={['ru', 'nk']}
                // value={formikRegular.values.phone_mobile}
                onChange={handlePhoneMobile}
                // onBlur={formikRegular.handleBlur}
                inputProps={{
                  required: true,
                  type: 'tel',
                  autoComplete: 'tel-national',
                  id: 'phone_mobile',
                  name: 'phone_mobile',
                  inputMode: 'tel',
                  className: 'form-control phone-input',
                  maxLength: 17
                }}
               
              />
              </div>
              <div className="col-sm-6">
                {/* <Input
                  name="phone_home"
                  value={formikRegular.values.phone_home}
                  type="tel"
                  placeholder="Phone Number 2(Home-optional)"
                  handleChange={formikRegular.handleChange}
                  handleBlur={formikRegular.handleBlur}
                  error={
                    formikRegular.errors.phone_home && formikRegular.touched.phone_home
                      ? formikRegular.errors.phone_home
                      : null
                  }
                /> */}
              <span style={{ fontSize: 'xx-small' }}>Phone Number 2(Home-optional)</span>
              <PhoneInput
                country={'ng'}
                preferredCountries={['ng', 'gh', 'ke', 'us', 'uk']}
                excludeCountries={['ru', 'nk']}
                value={formikRegular.values.phone_home}
                onChange={handlePhoneHome}
                inputProps={{
                  required: true,
                  type: 'tel',
                  autoComplete: 'tel-national',
                  id: 'phone_home',
                  name: 'phone_home',
                  inputMode: 'tel',
                  className: 'form-control phone-input',
                  maxLength: 17
                }}
                error={
                  formikRegular.errors.phone_home && formikRegular.touched.phone_home
                    ? formikRegular.errors.phone_home
                    : null
                }
              />
              </div>
              <div className="col-sm-6">
              <span style={{ fontSize: 'xx-small' }}>&nbsp;</span>
                <div className="mb-3">
                  <SelectBox
                    selectName="donation_group"
                    selectLabel={`Donation category`}
                    itemList={donation_groups}
                    isMulti={false}
                    value={formikRegular.values.donation_group}
                    onChange={handleSelectRegularchange}
                    selectOption={`Category`}
                    error={
                      formikRegular.errors.donation_group &&
                      formikRegular.touched.donation_group
                        ? formikRegular.errors.donation_group
                        : null
                    }
                  />
                </div>
              </div>
           
              {formikRegular.values?.donation_group && (
                <div className="col-sm-6">
                  <div className="mb-3">
                    <SelectBox
                      selectName="donation_cause"
                      selectLabel={`Donation Cause`}
                      itemList={
                        formikRegular.values?.donation_group?.value === 'fundraisers'
                          ? fundraiserCause
                          : formikRegular.values?.donation_group?.value === 'activity'|| 
                          formikRegular.values?.donation_group?.value === 'challenge'|| formikRegular.values?.donation_group?.value === 'campaign'
                            ? donationCause
                            : []
                      }
                      isMulti={false}
                      value={formikRegular.values.donation_cause}
                      onChange={handleSelectRegularchange}
                      selectOption={`Select cause`}
                      error={
                        formikRegular.errors.donation_cause &&
                        formikRegular.touched.donation_cause
                          ? formikRegular.errors.donation_cause
                          : null
                      }
                    />
                  </div>
                </div>
              )}
              <div className="col-sm-6">
                <div className="mb-3">
                  <label htmlFor="amount">Amount </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                      {param?.state?.currency==="USD"?'$':'₦'}
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      name="amount"
                      aria-label="amount"
                      value={formikRegular.values.amount}
                      onChange={formikRegular.handleChange}
                      onBlur={formikRegular.handleBlur}
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  {formikRegular.errors.amount && formikRegular.touched.amount && (
                    <small className="error">{formikRegular.errors.amount} </small>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end mt-4 mb-0">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={
                    registerUserMutation.isLoading ||
                    createDonationMutation.isLoading
                  }
                  onClick={() => scrollToErrors(formikRegular.errors)}
                >
                  {(registerUserMutation.isLoading ||
                    createDonationMutation.isLoading) && (
                      <LoadingIndicator />
                    )}{' '}
                  Donate
                </button>
              </div>
            </form>
          </div>
        </div>
              {/* end new */}
              
             
            </div>
           
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingThree">
          <button
            className="accordion-button bg-primary text-white collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
          >
            Click here to give once
          </button>
        </h2>
        <div
          id="collapseThree"
          className="accordion-collapse collapse"
          aria-labelledby="headingThree"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <form onSubmit={formik2.handleSubmit} className="row">
              <div className="col-sm-6">
                <Input
                  name="first_name"
                  value={formik2.values.first_name}
                  type="text"
                  placeholder="First Name"
                  handleChange={formik2.handleChange}
                  handleBlur={formik2.handleBlur}
                  error={
                    formik2.errors.first_name && formik2.touched.first_name
                      ? formik2.errors.first_name
                      : null
                  }
                />
              </div>
              <div className="col-sm-6">
                <Input
                  name="last_name"
                  value={formik2.values.last_name}
                  type="text"
                  placeholder="Last Name"
                  handleChange={formik2.handleChange}
                  handleBlur={formik2.handleBlur}
                  error={
                    formik2.errors.last_name && formik2.touched.last_name
                      ? formik2.errors.last_name
                      : null
                  }
                />
              </div>
              <div className="col-sm-6">
                <Input
                  name="email"
                  value={formik2.values.email}
                  type="email"
                  placeholder="Email"
                  handleChange={formik2.handleChange}
                  handleBlur={formik2.handleBlur}
                  error={
                    formik2.errors.email && formik2.touched.email
                      ? formik2.errors.email
                      : null
                  }
                />
              </div>
              <div className="col-sm-6">
                <Input
                  name="phone_mobile"
                  value={formik2.values.phone_mobile}
                  type="tel"
                  placeholder="Phone Number 1(Mobile)"
                  handleChange={formik2.handleChange}
                  handleBlur={formik2.handleBlur}
                  error={
                    formik2.errors.phone_mobile && formik2.touched.phone_mobile
                      ? formik2.errors.phone_mobile
                      : null
                  }
                />
              </div>
              <div className="col-sm-6">
                <Input
                  name="phone_home"
                  value={formik2.values.phone_home}
                  type="tel"
                  placeholder="Phone Number 2(Home-optional)"
                  handleChange={formik2.handleChange}
                  handleBlur={formik2.handleBlur}
                  error={
                    formik2.errors.phone_home && formik2.touched.phone_home
                      ? formik2.errors.phone_home
                      : null
                  }
                />
              </div>
              <div className="col-sm-6">
                <div className="mb-3">
                  <SelectBox
                    selectName="donation_group"
                    selectLabel={`Donation category`}
                    itemList={donation_groups}
                    isMulti={false}
                    value={formik2.values.donation_group}
                    onChange={handleSelect2change}
                    selectOption={`Category`}
                    error={
                      formik2.errors.donation_group &&
                        formik2.touched.donation_group
                        ? formik2.errors.donation_group
                        : null
                    }
                  />
                </div>
              </div>
              {formik2.values?.donation_group && (
                <div className="col-sm-6">
                  <div className="mb-3">
                    <SelectBox
                      selectName="donation_cause"
                      selectLabel={`Donation Cause`}
                      // itemList={
                      //   formik2.values?.donation_group?.value === 'fundraisers'
                      //     ? fundraiserCause
                      //     : formik2.values?.donation_group?.value === 'others'
                      //       ? donationCause
                      //       : []
                      // }
                      itemList={
                        formik2.values?.donation_group?.value === 'fundraisers'
                          ? fundraiserCause
                          : formik2.values?.donation_group?.value === 'activity'|| 
                          formik2.values?.donation_group?.value === 'challenge'|| formik2.values?.donation_group?.value === 'campaign'
                            ? donationCause
                            : []
                      }
                      isMulti={false}
                      value={formik2.values.donation_cause}
                      onChange={handleSelect2change}
                      selectOption={`Select cause`}
                      error={
                        formik2.errors.donation_cause &&
                          formik2.touched.donation_cause
                          ? formik2.errors.donation_cause
                          : null
                      }
                    />
                  </div>
                </div>
              )}
              <div className="col-sm-6">
                <div className="mb-3">
                  <label htmlFor="amount">Amount </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        ₦
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      name="amount"
                      aria-label="amount"
                      value={formik2.values.amount}
                      onChange={formik2.handleChange}
                      onBlur={formik2.handleBlur}
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  {formik2.errors.amount && formik2.touched.amount && (
                    <small className="error">{formik2.errors.amount} </small>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end mt-4 mb-0">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={
                    registerUserMutation.isLoading ||
                    createDonationMutation.isLoading
                  }
                  onClick={() => scrollToErrors(formik.errors)}
                >
                  {(registerUserMutation.isLoading ||
                    createDonationMutation.isLoading) && (
                      <LoadingIndicator />
                    )}{' '}
                  Donate
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationCommon;
