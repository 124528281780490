import axios from 'axios'
import { baseURL } from 'hook/useAxios';
import { atom, selector, selectorFamily } from 'recoil';
const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});
function getAllCountries() {
  return axiosInstance.get('/generic/countries');
}
function getStateByCountry(id) {
  return axiosInstance.get(`/generic/states/${id}`);
}
function getCitiesByState(id) {
  return axiosInstance.get(`/generic/cities/${id}`);
}

function getCauses(payload){
  debugger ;
    if(payload.label=="Fundraiser")
      return axiosInstance.get(`/generic/fund-raisings`);
    if(payload.label=="Activity")
      return axiosInstance.get(`/generic/fund-raisings`);
    if(payload.label=="Challenge")
      return axiosInstance.get(`/generic/fund-raisings`);
    if(payload.label=="Campaign")
      return axiosInstance.get(`/generic/fund-raisings`);
  }



export const mapCountriesToState = selector({
  key: 'countriesState',
  get: async () => {
    const response = await getAllCountries()
    if (response.error) {
      throw response.error;
    }
    return response?.data?.data?.countries;
  },
})

export const getStatesByCountryId = selectorFamily({
  key: 'stateByCountryId',
  get: (id) => async () => {
    if (id) {
      const response = await getStateByCountry(id)
      if (response.error) {
        throw response.error;
      }
      return response?.data?.data?.states;
    } else {
      return null
    }
  },
})
export const getCauseCategoryById = selectorFamily({
  key: 'causeById',
  get: (id) => async () => {
    if (id) {
      const response = await getCauses(id)
      if (response.error) {
        throw response.error;
      }
      return response?.data?.data?.states;
    } else {
      return null
    }
  },
})

export const getCitiesByStateId = selectorFamily({
  key: 'citiesByStateId',
  get: (id) => async () => {
    if (id) {
      const response = await getCitiesByState(id)
      if (response.error) {
        throw response.error;
      }
      return response?.data?.data?.cities;
    } else {
      return null
    }
  },
})

export const selectedDonorAtom = atom({
  key: 'selectedDonor',
  default: null
});
