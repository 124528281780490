import { format } from "date-fns"
import React, { useEffect } from "react"
import { useQuery } from "react-query"
import { formatMoney } from "utils/functions"
import makeData from "utils/makeData"
import useReports from "./useReports"
import ButtonWithTips from "components/ButtonWithTips"

const useRecycleReport = () => {
  const { getRecycleReport } = useReports()
  const [page, setPage] = React.useState(1)
  const [sFrom, setFrom] = React.useState('');
  const [sTo, setTo] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [cycle, setSearchCycle] = React.useState('');
  // const data =[];
  const { data, isLoading } = useQuery([page, search, cycle,sFrom,sTo],() =>  getRecycleReport(page, search, cycle,sFrom,sTo), {
   onSuccess:(res)=>{},
    select: data => data.data.donation_report
  })
 
  const recycleReport = useQuery([ page, search, cycle,sFrom,sTo], () => getRecycleReport(page, search, cycle,sFrom,sTo), {
    onSuccess: (res) => {
      // setDonors(res.data.users.data)
      // const permOpt = res.data.donation_report.map(item => {
      //   return { id: item.id, value: item.id, email: item.email, phone: item.phone_mobile, label: toFullName({ firstName: item.first_name, lastName: item.last_name }) }
      // })
      // setData(res.data.donation_report)
    },
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
    staleTime: Infinity,
    cacheTime: Infinity
  })
  function getExcel(){  
    getRecycleReport(page, search, cycle,sFrom,sTo,"export")
    .then((response) => {    
     const url = window.URL.createObjectURL(new Blob([response]));
     const link = document.createElement('a');
     link.href = url;
     link.setAttribute('download', `recycleReport.xlsx`);
     document.body.appendChild(link);
     link.click(); 
  })
}
  const [, setData] = React.useState(React.useMemo(() => makeData('aausers', 100), []))
  const [currentData, setCurrentData] = React.useState(null)
  const [current, setCurrent] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [edit, setEdit] = React.useState(false)
  const [bar_data, setBar_data] = React.useState([])
  const [line_data, setLine_data] = React.useState([])
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  const updateMyData = () => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setData(old =>
      old.map((row) => {
        if (row.id === currentData.id) {
          return {
            ...currentData
          }
        }
        return row
      })
    )

    setOpen(false)
  }

  const dummy_data = {
    labels: bar_data.length ? bar_data.map(item => format(new Date(item.date), 'MMM')) : [],
    datasets: [
      {
        label: "donations(NGN)",
        backgroundColor: "rgba(2,117,216,1)",
        borderColor: "rgba(2,117,216,1)",
        data: bar_data.length ? bar_data.map(item => item.amount) : [],
      },
    ],
  };

  const dummy_line_data = {
    labels: line_data.length ? line_data.map(item => format(new Date(item.date), 'MMM dd')) : [],
    datasets: [
      {
        fill: true,
        label: "Total Amount(NGN)",
        lineTension: 0.3,
        backgroundColor: "rgba(2,117,216,0.2)",
        borderColor: "rgba(2,117,216,1)",
        pointRadius: 5,
        pointBackgroundColor: "rgba(2,117,216,1)",
        pointBorderColor: "rgba(255,255,255,0.8)",
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(2,117,216,1)",
        pointHitRadius: 50,
        pointBorderWidth: 2,
        data: line_data.length ? line_data.map(item => item.amount) : [],
      },
    ],
  };

  useEffect(() => {
    const donationReportByMonth = async () => {
      const today = format(new Date(), 'yyyy/MM/dd')
      const date = today.split('/')[0]
      const records = []
      const allRecord = []
      data.forEach(item => {
        if (item?.created_at?.includes(date)) {
          records.push({ date: format(new Date(item.created_at), 'yyyy/MM/dd'), amount: item.transaction?.amount })
          allRecord.push({ cycle: item.donation_cycle, amount: item.transaction?.amount })
        }
      })

      const occurrencesAmount = records.sort((p1, p2) => (p1.date > p2.date) ? 1 : (p1.date < p2.date) ? -1 : 0).reduce(function (allCycles, cycle) {
        if (allCycles.some(function (e) {
          return e.date.split('/').splice(0, 2).join('/') === cycle.date.split('/').splice(0, 2).join('/')
        })) {
          allCycles.filter(function (e) {
            return e.date.split('/').splice(0, 2).join('/') === cycle.date.split('/').splice(0, 2).join('/')
          })[0].amount += +cycle.amount
        } else {
          allCycles.push({
            date: cycle.date,
            amount: +cycle.amount
          })
        }
        return allCycles
      }, []);

      setBar_data(occurrencesAmount)
    }


    const donationsThisMonthFunc = () => {
      const today = format(new Date(), 'yyyy/MM/dd')
      const date = today.split('/').splice(0, 2).join('-')
      const records = []
      data.forEach(item => {
        if (item?.date?.includes(date)) {
          records.push({ date: format(new Date(item.created_at), 'yyyy/MM/dd'), amount: item.transaction?.amount })
        }
      })
      const reduced = records.sort((p1, p2) =>
        (p1.date > p2.date) ? 1 : (p1.date < p2.date) ? -1 : 0).reduce(function (allDates, date) {
          if (allDates.some(function (e) {
            return e.date === date.date
          })) {
            allDates.filter(function (e) {
              return e.date === date.date
            })[0].amount += +date.amount
          } else {
            allDates.push({
              date: date.date,
              amount: +date.amount
            })
          }
          return allDates
        }, []);
      setLine_data(reduced)
    }

    if (data) {
      donationReportByMonth()
      donationsThisMonthFunc()
    }
  }, [data])

  const handleclick = (obj) => {
    setOpen(true)
    setCurrentData(obj)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        id: 'date',
        accessor: row => format(new Date(row?.created_at), 'MMM dd yyyy'),
        filter: 'dateFilter'
      },
      {
        Header: 'Name',
        accessor: 'donor_name',
      },
      {
        Header: 'Phone Number',
        accessor: 'donor_phone_number',
      },
      {
        Header: 'Cycle',
        accessor: 'donation_cycle',
      },
      {
        Header: 'Amount',
        id: 'donation',
        accessor: row => formatMoney(row?.transaction?.amount)
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: row => (
          <div className="flex flex-wrap gap-2 w-100">
            <ButtonWithTips id={`view`} tips="View Report Details" btnProps={{
              className: "btn btn-secondary", onClick: () => {
                setCurrent('view')
                handleclick(row.row.original)
              }
            }}><i className="fas fa-eye" /></ButtonWithTips>
          </div>
        ),
      }
    ],
    []
  )
  return { 
    currentData, setCurrentData,
     open, setOpen, updateMyData, skipPageReset, columns,
    setSearchCycle,setSearch,setFrom,setTo,page,setPage,getExcel,
     data,recycleReport, setData,
      current, 
      edit, setEdit,
       dummy_data, dummy_line_data,
        isLoading
     }
}

export default useRecycleReport;
